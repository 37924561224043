<!-- =========================================================================================
  File Name: FullPage.vue
  Description: Full page layout
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--full-page footer-sticky" :class="activeUserInfo.template === 1 ? 'full-dark' : ''">
    <router-view></router-view>
    <the-footer :home-data="homeData">
      <template slot="data">
        <div v-if="homeData != null && homeData.contact_details" class="flex">
          <div class="flex items-end leading-none mr-4">
            <p style="color: #626262; font-weight: normal;">Support</p>
          </div>
          <div class="flex items-end leading-none mr-4" >
            <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4" />
            <a style="color: #626262;" :href="'tel:' + homeData.contact_details.phone"><span class="mt-1">{{ homeData.contact_details.phone }}</span></a>
          </div>
          <div class="flex items-end leading-none" v-if="homeData.contact_details.whatsapp">
            <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg" class="mr-4" />
            <!--              <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4" />-->
            <a style="color: #626262;" :href="'https://wa.me/' + homeData.contact_details.whatsapp.replace(' ', '')"
               target="_blank">
              <span class="mt-1">{{ homeData.contact_details.whatsapp }}</span>
            </a>
          </div>
        </div>
      </template>
    </the-footer>
  </div>
</template>
<script>

import TheFooter from '@/layouts/components/TheFooter.vue'

export default {
  name: 'FullPage',
  components: {
    TheFooter,
  },
  data() {
    return {
      homeData: null,
    }
  },
  computed: {

    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    getHomeData() {
      try {
        this.homeData = JSON.parse(localStorage.getItem('home'))
      } catch (e) {
        return null
      }
    },
  },
  mounted() {
    this.getHomeData()
  },
}
</script>
<style scoped>
.footer-sticy {
  width: 100%;
}
</style>
